import React, { useMemo, useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import {
  getUsersPendingInvite,
  useUsersPendingInvitesCount,
  useUserPendingInvite,
  deleteUsersInvite,
  inviteUsers,
} from "services/OrganisationService";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import PendingInvitesPanel from "ui/pendingInvitesPanel/PendingInvitesPanel";
import { useStyles } from "./style";
import { UserInviteAvatar } from "./components/UserInviteAvatar";
import { OrganisationStrings, DefaultStrings } from "strings";
import { OverflowMenu } from "components";
import { SendIcon, DeleteIcon } from "assets/icons";

function PendingApprovalsCard({ organisationId, canWrite }) {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [showInvites, setShowInvites] = useState(false);
  const [invitesCodes, setInvitesCodes] = useState();
  const [totalInvitesCodes, setTotalInvitesCodes] = useState();

  const invitesCount = useUsersPendingInvitesCount(organisationId);
  const userInvite = useUserPendingInvite({
    code: invitesCodes && invitesCodes.length ? invitesCodes[0] : null,
    organisationId,
  });

  const description = useMemo(() => {
    if (!invitesCodes || !invitesCodes.length) return null;

    if (invitesCodes.length === 1) {
      return `${userInvite?.email || ""} ${
        mobile
          ? OrganisationStrings.PENDING_INVITES_ONE
          : OrganisationStrings.PENDING_INVITES_HAS_PENDING_INVITE
      }`;
    }

    if (mobile)
      return `${invitesCodes.length} ${OrganisationStrings.PENDING_INVITES_MANY}`;

    if (invitesCodes.length - 1 === 1)
      return `${userInvite?.email || ""} and ${invitesCodes.length - 1} ${
        OrganisationStrings.PENDING_INVITES_OTHER_HAVE_PENDING_INVITE
      }`;

    return `${userInvite?.email || ""} and ${invitesCodes.length - 1} ${
      OrganisationStrings.PENDING_INVITES_OTHERS_HAVE_PENDING_INVITE
    }`;
  }, [invitesCodes, mobile, userInvite]);

  const fetchData = useCallback(async () => {
    const requestParams = {
      organisationId,
    };

    try {
      const res = await getUsersPendingInvite(requestParams);
      const responseData = res.result;
      const { invitesIds, total } = responseData;

      setTotalInvitesCodes(total);
      setInvitesCodes(invitesIds);
    } catch (err) {
      console.log(err);
    }
  }, [organisationId]);

  useEffect(() => {
    if (organisationId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    if (
      organisationId &&
      typeof totalInvitesCodes !== "undefined" &&
      invitesCount !== totalInvitesCodes
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, totalInvitesCodes, invitesCount]);

  useEffect(() => {
    if (invitesCodes?.length === 0) {
      setShowInvites(false);
    }
  }, [invitesCodes]);

  const onShowHandle = () => setShowInvites(true);
  const onCloseHandle = () => setShowInvites(false);

  const menuResendAll = {
    label: OrganisationStrings.INVITES_RESEND_INVITATIONS,
    disabled: !canWrite,
    onClick: async () => {
      if (invitesCodes.length === 0) return;

      const { onProgress, onClose } = await confirm({
        title: OrganisationStrings.INVITES_RESEND_INVITATIONS_CONFIRM_TITLE,
        message: OrganisationStrings.INVITES_RESEND_INVITATIONS_CONFIRM_DESC,
        config: {
          icon: <SendIcon />,
          btOkColor: "primary",
          btOkTitle: DefaultStrings.BUTTON_SEND,
          cbAction: true,
        },
      });

      onProgress(true);

      try {
        await inviteUsers({ organisationId });
        enqueueSnackbar(OrganisationStrings.INVITE_RESEND_ALL_SUCCESS, {
          variant: "success",
        });
      } catch (err) {
        console.warn(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      } finally {
        onClose();
        onProgress(false);
      }
    },
  };

  const menuDeleteAll = {
    label: OrganisationStrings.INVITES_DELETE_ALL,
    onClick: async () => {
      const { onProgress, onClose } = await confirm({
        title: OrganisationStrings.INVITES_DELETE_ALL_CONFIRM_TITLE,
        message: OrganisationStrings.INVITES_DELETE_ALL_CONFIRM_DESC,
        config: {
          icon: <DeleteIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_DELETE,
          cbAction: true,
        },
      });

      onProgress(true);

      try {
        await deleteUsersInvite({ organisationId });
        enqueueSnackbar(OrganisationStrings.INVITE_DELETE_ALL_SUCCESS, {
          variant: "success",
        });
      } catch (err) {
        console.warn(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      } finally {
        onProgress(false);
        onClose();
      }
    },
    disabled: !canWrite,
    color: "error",
  };

  if (!invitesCount || !invitesCodes?.length) return null;

  return (
    <>
      <Card className={classes.root}>
        <div className={classes.contentWrapper}>
          {!mobile && (
            <div className={classes.avatarsWrapper}>
              {invitesCodes?.map((code, idx) => (
                <UserInviteAvatar
                  key={code}
                  index={idx}
                  code={code}
                  organisationId={organisationId}
                />
              ))}
            </div>
          )}
          {description && (
            <Typography variant="body2">{description}</Typography>
          )}
        </div>
        <div className={classes.actionsWrapper}>
          <Button
            className={classes.button}
            onClick={onShowHandle}
            disabled={!canWrite}
          >
            {OrganisationStrings.PENDING_INVITES_BUTTON_TITLE}
          </Button>
          <OverflowMenu options={[menuResendAll, menuDeleteAll]} />
        </div>
      </Card>
      <PendingInvitesPanel
        organisationId={organisationId}
        open={showInvites}
        onClose={onCloseHandle}
        canWrite={canWrite}
      />
    </>
  );
}

export default PendingApprovalsCard;
