import React, { useState, useMemo, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import { useSnackbar } from "notistack";

import ProfileDialog from "ui/dialogs/ProfileDialog";
import { DefaultStrings, TeamCodeStrings } from "strings";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import { userIsAdmin } from "services/AuthService";
import { PROJECT_PERMISSIONS } from "utils/permissionUtils";
import { Select } from "components";
import { UserRemoveIcon } from "assets/icons";

export const TeamMemberDialog = (props) => {
  const {
    userInfo,
    userId,
    permissions,
    permissionNames,
    confirmTitle,
    confirmDesc,
    onProgressId,
    onClose,
    onRemoveUser,
    onUpdateUserRole,
  } = props;
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const {
    canDeleteUsers,
    canWriteUserPermissions,
    canReadUserPermissions,
    userPermissions,
  } = permissions;
  const [newPermissionName, setNewPermissionName] = useState(
    userInfo?.permissionsKey
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    userIsAdmin().then((res) => setIsSuperAdmin(res));
  }, []);

  useEffect(() => {
    if (userInfo?.permissionName) {
      const permissionKey = permissionNames.find(
        (item) => userInfo?.permissionsKey === item.value
      );
      if (permissionKey) {
        setNewPermissionName(permissionKey.value);
      }
    }
  }, [permissionNames, userInfo]);

  const isSelectedSelf = useMemo(
    () => userInfo && userId === userInfo?.userId,
    [userId, userInfo]
  );

  const selectedAdmin = useMemo(
    () => userInfo && userInfo?.permissionsKey === PROJECT_PERMISSIONS.ADMIN,
    [userInfo]
  );

  const canDeleteUserSelected = useMemo(
    () => isSuperAdmin || (!isSelectedSelf && canDeleteUsers && !selectedAdmin),
    [canDeleteUsers, isSelectedSelf, isSuperAdmin, selectedAdmin]
  );

  const permissionsOptions = useMemo(() => {
    const permIndex = permissionNames.findIndex(
      (item) => userPermissions?.permissionsKey === item.value
    );
    let perms = permissionNames;

    if (permIndex !== -1 && !isSuperAdmin) {
      perms = permissionNames.slice(permIndex);
    }

    if (userInfo?.userName) {
      perms = [
        ...perms,
        {
          title: `${DefaultStrings.BUTTON_REMOVE} ${userInfo?.userName}`,
          value: "remove-user",
          disabled: !canDeleteUserSelected,
        },
      ];
    }
    return perms;
  }, [
    canDeleteUserSelected,
    isSuperAdmin,
    permissionNames,
    userInfo,
    userPermissions,
  ]);

  const canEditPermissionsSelected = useMemo(
    () =>
      isSuperAdmin ||
      (!isSelectedSelf && canWriteUserPermissions && !selectedAdmin),
    [canWriteUserPermissions, isSelectedSelf, isSuperAdmin, selectedAdmin]
  );

  const config = {
    showButtonBar: true,
    disableOk:
      newPermissionName && userInfo?.permissionsKey === newPermissionName,
    onClose: () => onClose(),
    onOk: async () => {
      onProgressId(userInfo.userId);
      try {
        await onUpdateUserRole(newPermissionName);
        enqueueSnackbar(TeamCodeStrings.UPDATE_TEAM_MEMBER_ROLE, {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      } finally {
        onProgressId(null);
        onClose();
      }
    },
  };

  const permissionChangeHandle = async (event) => {
    if (event.target.value === "remove-user") {
      const { onProgress, onClose: onConfirmClose } = await confirm({
        title: confirmTitle.replace("{name}", userInfo.userName),
        message: confirmDesc.replace("{name}", userInfo.userName),
        config: {
          icon: <UserRemoveIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_REMOVE,
          cbAction: true,
        },
      });
      onProgress(true);

      try {
        await onRemoveUser(userInfo.userId);

        enqueueSnackbar(TeamCodeStrings.TEAM_MEMBER_REMOVED, {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      } finally {
        onProgress(false);
        onConfirmClose();
        onClose();
      }
    } else {
      setNewPermissionName(event.target.value);
    }
  };

  return (
    <ProfileDialog
      userImage={userInfo?.userImage}
      open={!!userInfo}
      config={config}
      titleChildren={
        userInfo?.userNameLetters && (
          <Avatar className={classes.emptyMedia} variant="square">
            {userInfo.userNameLetters}
          </Avatar>
        )
      }
    >
      <Box pt={2}>
        <Typography className={classes.name}>{userInfo?.userName}</Typography>
        <Typography className={classes.email}>{userInfo?.userEmail}</Typography>
        {canReadUserPermissions && (
          <>
            {canEditPermissionsSelected ? (
              <Select
                value={newPermissionName}
                options={permissionsOptions}
                onChange={permissionChangeHandle}
                fullWidth
                highlightLast
              />
            ) : (
              <div className={classes.permission}>
                {userInfo?.permissionName}
              </div>
            )}
          </>
        )}
      </Box>
    </ProfileDialog>
  );
};
