import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    main: () => ({
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(2),
    }),
    container: {
      padding: theme.spacing(1),
      flex: "1 0 auto",
    },
  };
});
