import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";

import RestrictedContent from "ui/RestrictedContent";
import { TeamMemberDialog } from "ui/dialogs";
import {
  deleteOrganisationUser,
  updateOrganisationUserRole,
  getOrganisationUsers,
  useUserOrganisationPermissions,
  useOrganisationUsersCount,
  useOrganisationGuestsCount,
} from "services/OrganisationService";
import { useMobileLayout } from "hooks/uiHooks";
import {
  ORG_PERMISSION_NAMES,
  ORGANISATION_PERMISSIONS,
} from "utils/permissionUtils";
import { ROUTES } from "route";
import {
  TEAM_ORGANISATION_SORT_OPTION_FIELD,
  TEAM_ORGANISATION_SORT_OPTION,
} from "utils/teamUtils";
import { useStyles } from "./style";
import { OrganisationStrings } from "strings";
import { isLoading } from "utils/uiUtils";
import InfiniteScroll from "ui/InfiniteScroll";
import TeamMemberCard from "ui/cards/teamMemberCard/TeamMemberCard";
import Spinner from "ui/Spinner";
import { Select } from "components";
import { USER_ROLE_OPTIONS, USER_ROLE_VALUES } from "./config";
import { EmptyData } from "ui/emptyData";
import PendingInvitesCard from "ui/cards/pendingInvitesCard/PendingInvitesCard";
import { PeopleIcon, SearchIcon } from "assets/icons";

const Team = forwardRef((props, ref) => {
  const { userId } = props;
  const { organisationId } = useParams();
  const isTeamPage = useRouteMatch(ROUTES.ORGANISATION_TEAM);
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const [userInfo, setUserInfo] = useState(null);
  const [progressId, setProgressId] = useState();
  const [startAfter, setStartAfter] = useState();
  const [usersIds, setUsersIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [selectedRole, setSelectedRole] = useState(USER_ROLE_VALUES.ALL);
  const [totalUsers, setTotalUsers] = useState();
  const [loading, setLoading] = useState(false);

  const usersTotalCount = useOrganisationUsersCount(
    isTeamPage && organisationId
  );
  const guestsTotalCount = useOrganisationGuestsCount(
    !isTeamPage && organisationId
  );

  // permissions control
  const permissions = useUserOrganisationPermissions({
    userId,
    organisationId,
  });
  const { canReadUsers, canReadUserPermissions, canWriteOrganisation } =
    permissions;

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const permissionNames = useMemo(() => {
    if (userInfo?.permissionsKey === ORGANISATION_PERMISSIONS.GUEST) {
      return [
        {
          title: OrganisationStrings.PERMISSIONS_GUEST,
          value: ORGANISATION_PERMISSIONS.GUEST,
        },
      ];
    }
    return ORG_PERMISSION_NAMES;
  }, [userInfo]);

  const emptyDataText = useMemo(() => {
    if (usersIds && usersIds.length) return {};

    if (isTeamPage) {
      return {
        title: props.searchValue
          ? OrganisationStrings.USERS_NO_FOR_SEARCH
          : OrganisationStrings.USERS_NO_IN_ORGANISATION,

        description: OrganisationStrings.USERS_NO_IN_ORGANISATION_DESC,
        actionTitle: props.searchValue ? null : null, // TODO: here will be text to add a new team member
      };
    }

    return {
      title: props.searchValue
        ? OrganisationStrings.USERS_NO_GUESTS_FOR_SEARCH
        : OrganisationStrings.USERS_NO_GUESTS_IN_ORGANISATION,

      description: OrganisationStrings.USERS_NO_GUESTS_IN_ORGANISATION_DESC,
      actionTitle: null,
    };
  }, [isTeamPage, props.searchValue, usersIds]);

  const getParams = useCallback(
    (params) => {
      const { searchValue, sortDesc, lastKey, userRole } = params;
      const orderBy =
        params.sortValue &&
        params.sortValue in TEAM_ORGANISATION_SORT_OPTION_FIELD
          ? TEAM_ORGANISATION_SORT_OPTION_FIELD[params.sortValue]
          : TEAM_ORGANISATION_SORT_OPTION_FIELD[
              TEAM_ORGANISATION_SORT_OPTION[0]
            ];

      return {
        limit: 20,
        orderBy,
        searchValue,
        lastKey,
        orderDesc: sortDesc,
        userRole,
        userRoles: isTeamPage
          ? [
              ORGANISATION_PERMISSIONS.ADMIN,
              ORGANISATION_PERMISSIONS.CREATOR,
              ORGANISATION_PERMISSIONS.VIEWER,
            ]
          : [ORGANISATION_PERMISSIONS.GUEST],
      };
    },
    [isTeamPage]
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        userRole: selectedRole,
        ...props,
        ...params,
      });

      setLoading(true);

      try {
        const response = await getOrganisationUsers({
          organisationId,
          ...requestParams,
        });
        const { items, total } = response.result;
        setTotalUsers(total);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setUsersIds([...usersIds, ...items]);
        } else {
          setUsersIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
    [getParams, isRequestSent, organisationId, props, selectedRole, usersIds]
  );

  useEffect(() => {
    if (organisationId) {
      // initial request on page loaded
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    // no previous request yet, skip next lines
    if (typeof totalUsers === "undefined") return;

    // do request if total count changed for organisation users
    if (
      (typeof usersTotalCount !== "undefined" &&
        totalUsers !== usersTotalCount) ||
      (typeof guestsTotalCount !== "undefined" &&
        totalUsers !== guestsTotalCount)
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTotalCount, guestsTotalCount, totalUsers]);

  useImperativeHandle(
    ref,
    () => ({
      doRequest: (opt) => {
        resetData();
        fetchData(opt);
      },
    }),
    [fetchData]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  const onProgressIdHandle = (userId) => setProgressId(userId);
  const onCloseHandle = () => setUserInfo(null);
  const onRemoveUserHandle = async () => {
    await deleteOrganisationUser({
      organisationId,
      userId: userInfo.userId,
    });
    fetchData();
  };

  const onUpdateUserRoleHandle = async (role) => {
    await updateOrganisationUserRole({
      organisationId,
      role,
      userId: userInfo.userId,
    });
    fetchData();
  };

  const configScroll = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      if (
        usersIds.length === totalUsers &&
        usersIds.length === (usersTotalCount || guestsTotalCount)
      )
        return;

      fetchData({ lastKey: startAfter });
    },
  };

  const configCard = {
    onClick: (user) => setUserInfo(user),
  };

  const onSelectHandle = (ev) => {
    const { name, value } = ev.target;

    if (name === "role") {
      resetData();
      setSelectedRole(value);
      fetchData({ userRole: value });
    }
  };

  const onClickClear = () => {
    resetData();
    setSelectedRole(USER_ROLE_VALUES.ALL);
    fetchData({
      userRole: USER_ROLE_VALUES.ALL,
    });
  };

  const renderContent = () => {
    if (isLoading(usersIds)) return <Spinner />;

    if (!usersIds.length)
      return (
        <EmptyData
          title={emptyDataText.title}
          description={!props.searchValue && emptyDataText.description}
          actionTitle={emptyDataText.actionTitle}
          icon={
            isTeamPage ? (
              props.searchValue ? (
                <SearchIcon />
              ) : (
                <PeopleIcon />
              )
            ) : props.searchValue ? (
              <SearchIcon />
            ) : (
              <SupervisedUserCircleRoundedIcon />
            )
          }
        />
      );

    return (
      <div className={classes.root}>
        <InfiniteScroll config={configScroll} size={usersIds.length}>
          {usersIds.map((id) => (
            <div key={id} className={classes.cardContainer}>
              <TeamMemberCard
                userId={id}
                organisationId={organisationId}
                config={configCard}
                canReadPermissions={canReadUserPermissions}
                progress={progressId === id}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  };
  return (
    <>
      {isTeamPage && (
        <>
          <PendingInvitesCard
            organisationId={organisationId}
            canWrite={canWriteOrganisation}
          />
          <div className={classes.filter}>
            <Select
              name="role"
              label={OrganisationStrings.SORT_OPTION_ROLE}
              value={selectedRole}
              options={USER_ROLE_OPTIONS}
              onChange={onSelectHandle}
            />
            <Button style={{ margin: "8px" }} onClick={onClickClear}>
              Clear
            </Button>
          </div>
        </>
      )}
      {loading && <Spinner />}
      <RestrictedContent permitted={canReadUsers}>
        {renderContent()}
      </RestrictedContent>
      <TeamMemberDialog
        userInfo={userInfo}
        userId={userId}
        permissions={permissions}
        permissionNames={permissionNames}
        confirmTitle={OrganisationStrings.TEAM_MEMBER_REMOVE_CONFIRM_TITLE}
        confirmDesc={OrganisationStrings.TEAM_MEMBER_REMOVE_CONFIRM_DESC}
        onProgressId={onProgressIdHandle}
        onRemoveUser={onRemoveUserHandle}
        onUpdateUserRole={onUpdateUserRoleHandle}
        onClose={onCloseHandle}
      />
    </>
  );
});

export default Team;
