import React, { useState } from "react";

import { PROJ_PERMISSION_NAMES } from "utils/permissionUtils";
import { TeamCodeStrings } from "strings";
import { useUserAwaitingApproval } from "services/ProjectService";
import SettingsDialog from "ui/dialogs/SettingsDialog";
import MultilineText from "ui/MultilineText";
import { VerifiedUserIcon } from "assets/icons";

export function UserRoleSelector({
  open,
  projectId,
  currentUserId,
  onClose,
  onOk,
  canWrite,
}) {
  const [userRole, setUserRole] = useState("");

  const userAwaitingApproval = useUserAwaitingApproval({
    userId: currentUserId,
    projectId,
  });

  const configDialog = {
    config: {
      selectTitle: TeamCodeStrings.PROJECT_DEFAULT_ROLE_SELECT_TITLE,
      description: (
        <MultilineText
          text={TeamCodeStrings.PROJECT_DEFAULT_ROLE_SELECT_DESC}
        />
      ),
      options: PROJ_PERMISSION_NAMES,
      canEdit: canWrite,
      hideLockedSwitch: true,
      onSelectChange: (event) => {
        const roleToUpdate = event.target.value;
        setUserRole(roleToUpdate);
      },
    },
    dialog: {
      icon: <VerifiedUserIcon />,
      title: TeamCodeStrings.PROJECT_USER_ROLE,
      onClose: onClose,
      onOk: () => onOk(userRole),
    },
  };

  return (
    <SettingsDialog
      open={open}
      config={configDialog}
      value={userRole || userAwaitingApproval?.permissionsKey}
    />
  );
}
