import React, { useState, useRef } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import Organisations from "./organisations/Organisations";
import Projects from "./projects/Projects";
import MainPage from "pages/MainPage";
import { OrganisationCreateDialog } from "ui/dialogs";
import { usePageConfig, PROJECT_SORT_OPTION_FIELD } from "./config";
import { ROUTES } from "route";
import { debounce } from "services/UiService";
import { ORGANISATION_SORT_OPTION_FIELD } from "utils/organisationUtils";

export const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ userId: uid });

const Index = ({ userId, match }) => {
  const projectsRef = useRef(null);
  const organisationsRef = useRef(null);
  const isOrgRoute = useRouteMatch(ROUTES.ADMIN_ORGANISATIONS);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [sortValue, setSortValue] = useState(
    isOrgRoute
      ? ORGANISATION_SORT_OPTION_FIELD.SORT_OPTION_DATE
      : PROJECT_SORT_OPTION_FIELD.SORT_OPTION_NAME
  );
  const [sortDescValue, setSortDescValue] = useState(true);

  const onCompleteHandle = () => {
    setOpenCreateDialog(false);
    organisationsRef.current.doRequest({
      sortValue,
      sortDesc: sortDescValue,
      searchValue,
    });
  };

  const onCloseHandle = () => setOpenCreateDialog(false);
  const onCreateHandle = () => setOpenCreateDialog(true);
  const onSearchHandle = debounce((value) => {
    if (searchValue === value) return;
    setSearchValue(value);

    if (isOrgRoute) {
      organisationsRef.current.doRequest({
        searchValue: value,
        sortValue,
        sortDesc: sortDescValue,
      });
    } else {
      projectsRef.current.doRequest({
        searchValue: value,
        sortValue,
        sortDesc: sortDescValue,
      });
    }
  }, 300);

  const onSortHandle = (opt) => {
    let sortDesc = sortDescValue;
    if (sortValue === opt) {
      sortDesc = !sortDescValue;
      setSortDescValue(!sortDescValue);
    }

    setSortValue(opt);

    if (isOrgRoute) {
      organisationsRef.current.doRequest({ sortValue: opt, sortDesc });
    } else {
      projectsRef.current.doRequest({ sortValue: opt, sortDesc });
    }
  };

  const { config } = usePageConfig({
    sortValue,
    sortDesc: sortDescValue,
    path: match.path,
    onCreate: onCreateHandle,
    onSearch: onSearchHandle,
    onSort: onSortHandle,
  });

  return (
    <MainPage config={{ userId, ...config }} match={match}>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN_ORGANISATIONS}
          render={() => (
            <Organisations
              ref={organisationsRef}
              searchValue={searchValue}
              sortValue={sortValue}
              sortDesc={sortDescValue}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN_PROJECTS}
          render={() => (
            <Projects
              ref={projectsRef}
              searchValue={searchValue}
              sortValue={sortValue}
              sortDesc={sortDescValue}
            />
          )}
        />
        <Route
          path="*"
          render={() => <Redirect to={ROUTES.ADMIN_ORGANISATIONS} />}
        />
      </Switch>
      <OrganisationCreateDialog
        open={openCreateDialog}
        onComplete={onCompleteHandle}
        onClose={onCloseHandle}
      />
    </MainPage>
  );
};

export const AdminPage = connect(mapStateToProps)(Index);
