import React, { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";

import { useStyles } from "./style";
import { useBreakpoints } from "hooks/uiHooks";

export const Select = ({
  name,
  label,
  value,
  icon,
  fullWidth,
  highlightLast,
  options,
  onChange,
  disabled,
}) => {
  const { isMobile } = useBreakpoints();
  const classes = useStyles({ isMobile, hasLabel: !!label });
  const [open, setOpen] = useState(false);

  const closeHandle = () => {
    setOpen(false);
  };

  const openHandle = () => {
    setOpen(true);
  };

  return (
    <FormControl variant="filled" fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <MuiSelect
        className={classes.select}
        name={name}
        value={value}
        open={open}
        onChange={onChange}
        onClose={closeHandle}
        onOpen={openHandle}
        disabled={disabled}
      >
        {options.map((option, idx) => (
          <MuiMenuItem
            key={`option-${option.value}`}
            className={classes.menuItem}
            style={{
              color:
                highlightLast && options.length - 1 === idx
                  ? "#B23A48"
                  : "#323232",
            }}
            value={option.value}
            disabled={option.disabled}
          >
            <Box sx={{ display: "flex" }}>
              {option.title}
              {icon && <div className={classes.icon}>{icon}</div>}
            </Box>
            {open && options.length - 1 !== idx && (
              <div className={classes.bottomBorder}></div>
            )}
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
