import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";

import Spinner from "./Spinner";
import InfiniteScroll from "./InfiniteScroll";
import EventCard, { groupEvents } from "./cards/EventCard";

import { isLoading } from "../utils/uiUtils";
import { getDateOnlyTime } from "../utils/localeUtils";
import { useMobileLayout } from "../hooks/uiHooks";
import { useUserEvents } from "../services/SystemEventService";
import { debounce, insertDateIntoMessages } from "../services/UiService";
import { useUserNewsLastRead } from "../services/UserService";
import { EmptyData } from "ui/emptyData";

import { DefaultStrings, NewsStrings } from "../strings";

const CONFIG = {
  AVATAR_SIZE: 5,
  EVENTS_COUNT: 12,
  MOBILE_DIVIDER: 2,
};

const useStyles = makeStyles((theme) => ({
  base: ({ mobile, empty }) => ({
    height: empty ? "100%" : "auto",
    display: "flex",
    flexDirection: "column",
    ...(mobile && { padding: `0px ${theme.spacing(1)}px` }),
  }),
  eventDate: {
    fontWeight: "bold",
  },
  moreContainer: ({ visible }) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
    backgroundColor: visible ? "white" : "transparent",
    minHeight: "1em",
    visibility: visible ? "visible" : "hidden",
  }),
}));

const More = ({ visible }) => {
  const classes = useStyles({ visible });
  return <div className={classes.moreContainer}>{DefaultStrings.LOADING}</div>;
};

const today = getDateOnlyTime();

const DateRow = ({ timestamp }) => {
  const classes = useStyles();
  const dateString =
    today === timestamp
      ? DefaultStrings.TODAY
      : new Date(timestamp).toLocaleDateString();
  return (
    <Box mt={4}>
      <Typography
        variant="h6"
        classes={{
          h6: classes.eventDate,
        }}
      >
        {dateString}
      </Typography>
    </Box>
  );
};

const DateOrEvent = ({ event, lastRead }) =>
  typeof event === "number" ? (
    <DateRow timestamp={event} />
  ) : (
    <EventCard key={event.id} event={event} lastRead={lastRead} />
  );

const SystemEventList = () => {
  const mobile = useMobileLayout();
  const count = mobile
    ? CONFIG.EVENTS_COUNT / CONFIG.MOBILE_DIVIDER
    : CONFIG.EVENTS_COUNT;
  const [limit, setLimit] = useState(count);
  const events = useUserEvents({ limit });
  const empty = events === null;
  const classes = useStyles({ mobile, empty });
  const lastReadRaw = useUserNewsLastRead();
  const [lastRead, setLastRead] = useState();
  const showMore = limit <= events?.length;

  // set the last read timestamp once
  useEffect(() => {
    if (lastRead || !lastReadRaw) return;
    setLastRead(lastReadRaw);
    console.debug("setLastRead", lastReadRaw);
  }, [lastReadRaw, lastRead]);

  if (isLoading(events)) return <Spinner />;

  const eventsWithDates = groupEvents(
    insertDateIntoMessages({
      messages: events,
      timestampKey: "timestamp",
    })
  );

  const config = {
    onLoadMore: debounce((size) => {
      if (size <= events?.length) {
        let inc = count;
        while (size + inc <= events?.length) inc += count;
        setLimit(limit + inc);
      }
    }, 500),
  };

  return (
    <div className={classes.base}>
      {empty ? (
        <EmptyData
          title={NewsStrings.NEWS_EMPTY_TITLE}
          description={NewsStrings.NEWS_EMPTY_DESC}
          icon={<NotificationsNoneRoundedIcon />}
        />
      ) : (
        <InfiniteScroll config={config} size={limit}>
          {eventsWithDates.map((event) => (
            <DateOrEvent
              key={`event-${event?.id ?? event}`}
              event={event}
              lastRead={lastRead}
            />
          ))}
          <More visible={showMore} />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SystemEventList;
