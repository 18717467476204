import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  codeTeam: ({ mobile }) => ({
    display: "flex",
    flexDirection: "column",
    padding: mobile
      ? theme.spacing(1)
      : `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    overflowX: "auto",
  }),
}));
