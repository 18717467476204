import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

import OverflowTypography from "ui/OverflowTypography";
import { useStyles } from "./style";

export const Content = ({
  children,
  startIcon,
  endIcon,
  variant = "body2",
  color = "textSecondary",
  className,
  noOverflow,
}) => {
  const StartIcon = startIcon;
  const EndIcon = endIcon;
  const classes = useStyles();
  const Container = noOverflow ? Typography : OverflowTypography;

  return (
    <div
      className={clsx(classes.content, !children && classes.hidden, className)}
    >
      {startIcon && (
        <StartIcon
          className={classes.textPrimary}
          style={{ marginRight: "5px" }}
        />
      )}
      <Container variant={variant} color={color}>
        {children}
      </Container>
      {endIcon && <EndIcon className={classes.textPrimary} />}
    </div>
  );
};
