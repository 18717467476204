import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    main: (mobile) => ({
      width: "100%",
      padding: theme.spacing(mobile ? 1 : 1),
      paddingTop: 0,
      display: "flex",
      flexWrap: mobile ? "wrap" : "nowrap",
      overflowY: "auto",
      height: "calc(100vh - 120px)",
    }),
    codes: (mobile) => ({
      width: mobile ? "100%" : 1,
      flexGrow: 2,
      padding: theme.spacing(1),
      margin: "16px 0",
    }),
    codesWrapper: (mobile) => ({
      display: "flex",
      flexWrap: mobile ? "nowrap" : "wrap",
      flexDirection: mobile ? "column" : "row",
      overflowY: "scroll",
    }),
  };
});
