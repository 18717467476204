import React from "react";

import SettingsCard from "ui/cards/SettingsCard";
import {
  GUEST_ACCESS_OPTIONS,
  PROJECT_ACCESS_OPTIONS,
  PROJECT_JOIN_APPROVAL_OPTIONS,
} from "configs/accessConfig";
import { PROJ_PERMISSION_NAMES } from "utils/permissionUtils";
import { TeamCodeStrings } from "strings";
import { updateProject } from "services/ProjectService";
import { useStyles } from "./style";
import {
  GuestIcon,
  ProjectIcon,
  ProjectJoinApprovalIcon,
  UserRoleIcon,
} from "assets/icons";
import MultilineText from "ui/MultilineText";

export const Settings = ({ info, canReadProject, canEditProject }) => {
  const classes = useStyles();

  const onUpdateHandle = (keyToUpdate) => (updateValue) => {
    return updateProject({
      projectId: info.projectId,
      info: { [keyToUpdate]: updateValue },
    });
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <SettingsCard
          value={info?.guestAccess}
          locked={info?.guestAccessLocked}
          options={GUEST_ACCESS_OPTIONS}
          icon={<GuestIcon />}
          title={TeamCodeStrings.GUEST_ACCESS_TITLE}
          selectTitle={TeamCodeStrings.GUEST_ACCESS_SELECT_TITLE}
          description={TeamCodeStrings.GUEST_ACCESS_SELECT_DESC}
          info={TeamCodeStrings.GUEST_ACCESS_INFO}
          errorMessage={TeamCodeStrings.GUEST_ACCESS_UPDATE_ERROR}
          canRead={canReadProject}
          canEdit={canEditProject}
          onOk={onUpdateHandle("guestAccess")}
          hideLockedSwitch
        />
      </div>
      <div className={classes.container}>
        <SettingsCard
          value={info?.projectAccess}
          locked={info?.projectAccessLocked}
          options={PROJECT_ACCESS_OPTIONS}
          icon={<ProjectIcon />}
          title={TeamCodeStrings.PROJECT_ACCESS_TITLE}
          selectTitle={TeamCodeStrings.PROJECT_ACCESS_SELECT_TITLE}
          description={
            <MultilineText text={TeamCodeStrings.PROJECT_ACCESS_SELECT_DESC} />
          }
          info={TeamCodeStrings.PROJECT_ACCESS_INFO}
          errorMessage={TeamCodeStrings.PROJECT_ACCESS_UPDATE_ERROR}
          canRead={canReadProject}
          canEdit={canEditProject}
          onOk={onUpdateHandle("projectAccess")}
          hideLockedSwitch
        />
      </div>
      <div className={classes.container}>
        <SettingsCard
          value={info?.defaultRole}
          locked={info?.defaultRoleLocked}
          options={PROJ_PERMISSION_NAMES}
          icon={<UserRoleIcon />}
          title={TeamCodeStrings.PROJECT_DEFAULT_ROLE_TITLE}
          selectTitle={TeamCodeStrings.PROJECT_DEFAULT_ROLE_SELECT_TITLE}
          description={
            <MultilineText
              text={TeamCodeStrings.PROJECT_DEFAULT_ROLE_SELECT_DESC}
            />
          }
          info={TeamCodeStrings.PROJECT_DEFAULT_ROLE_INFO}
          errorMessage={TeamCodeStrings.DEFAULT_ROLE_UPDATE_ERROR}
          canRead={canReadProject}
          canEdit={canEditProject}
          onOk={onUpdateHandle("defaultRole")}
          hideLockedSwitch
        />
      </div>
      <div className={classes.container}>
        <SettingsCard
          value={info?.joinApproval}
          locked={info?.joinApprovalLocked}
          options={PROJECT_JOIN_APPROVAL_OPTIONS}
          icon={<ProjectJoinApprovalIcon />}
          title={TeamCodeStrings.PROJECT_JOIN_APPROVAL_TITLE}
          selectTitle={TeamCodeStrings.PROJECT_JOIN_APPROVAL_SELECT_TITLE}
          description={
            <MultilineText
              text={TeamCodeStrings.PROJECT_JOIN_APPROVAL_SELECT_DESC}
            />
          }
          info={TeamCodeStrings.PROJECT_JOIN_APPROVAL_INFO}
          errorMessage={TeamCodeStrings.PROJECT_JOIN_APPROVAL_UPDATE_ERROR}
          canRead={canReadProject}
          canEdit={canEditProject}
          onOk={onUpdateHandle("joinApproval")}
          hideLockedSwitch
        />
      </div>
    </div>
  );
};
