import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";

import MyDialog from "ui/MyDialog";
import { PATTERN_ALPHANUMERIC } from "utils/generalUtils";
import PreviewDialog from "./components/PreviewDialog";
import { DefaultStrings, ProjectStrings } from "strings";
import { joinProject } from "services/ApiService";
import { getProjectOverviewRoute } from "route";
import { PROJECT_JOIN_APPROVAL } from "services/OrganisationService";

const JoinDialog = (props) => {
  const { open, config } = props;
  const { projectId, userId, hideViewBtn, onOk, onClose } = config;
  const params = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [editCode, setEditCode] = useState(null);
  const [joinCode, setJoinCode] = useState(params?.code || null);
  const [progress, setProgress] = useState(false);
  const [approvalRequested, setApprovalRequested] = useState(false);

  const configJoinDialog = {
    icon: <QueuePlayNextOutlinedIcon />,
    title: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
    onClose: () => {
      setEditCode(null);
      onClose();
    },
    onOk: () => {
      setJoinCode(editCode);
      setEditCode(null);
      onOk();
    },
    disableOk: editCode && editCode.length < 6,
  };

  const previewConfig = {
    code: joinCode,
    projectId,
    userId,
    approvalRequested,
    progress,
    hideViewBtn,
    onClose: () => {
      setJoinCode(null);
      onClose();
    },
    onConfirm: async (id, isView) => {
      if (isView) {
        history.push(getProjectOverviewRoute(id));
        onClose();
        return;
      }

      try {
        setProgress(true);
        const response = await joinProject({ projectId: id, joinCode });
        setProgress(false);

        if (response.result.joinApproval === PROJECT_JOIN_APPROVAL.REQUIRED) {
          setApprovalRequested(true);
          return;
        }

        setJoinCode(null);

        enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_SUCCEED, {
          variant: "success",
        });
        onClose(id);
      } catch (err) {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
        });
        console.error(err);
      }
    },
  };

  const onUpdateJoinCode = (e) => {
    const input = e.target;
    if (input.validity.valid) setEditCode(input.value);
  };

  return (
    <>
      <MyDialog open={open} config={configJoinDialog}>
        <TextField
          label={ProjectStrings.ADD_PROJECT_DIALOG_ENTER_CODE}
          value={editCode || ""}
          fullWidth
          onChange={onUpdateJoinCode}
          inputProps={{
            pattern: PATTERN_ALPHANUMERIC,
          }}
          autoFocus
        />
      </MyDialog>
      <PreviewDialog config={previewConfig} />
    </>
  );
};

export default JoinDialog;
