import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: ({ disableShadow }) => {
    const res = {
      height: "100%",
      position: "relative",
      flexGrow: 1,
    };
    if (disableShadow) res.boxShadow = "none;";
    return res;
  },
  action: ({ clickable }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: clickable ? "inherit" : 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  }),
  media: {
    flexGrow: 2,
    height: 1,
  },
  name: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  participants: {
    marginLeft: theme.spacing(2),
  },
  emptyParticipants: {
    display: "block",
    width: "32px",
    height: "32px",
  },
  desc: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    height: 1,
    whiteSpace: "pre-line",
  },
  orgNameWrapper: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  orgName: {
    textAlign: "end",
  },
  orgImageWrapper: {
    marginRight: theme.spacing(1),
  },
  avatarsWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));
