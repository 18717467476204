import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  itemContainer: {
    flex: "1 1 50%",
  },
  main: ({ mobile }) => ({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: mobile ? "column" : "row",
  }),
}));
