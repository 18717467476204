import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import UserAvatar from "ui/UserAvatar";
import { useMobileLayout } from "hooks/uiHooks";
import { TeamCodeStrings } from "strings";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const Creator = ({ userId }) => {
  const mobile = useMobileLayout();
  const classes = useStyles();

  const avatarCreatorSize = mobile ? 7 : 5;

  return (
    <div className={classes.container}>
      <Typography variant="body1">
        {TeamCodeStrings.TEAM_CODE_CREATOR}
      </Typography>
      <Box m={1}>
        <UserAvatar userId={userId} size={avatarCreatorSize} />
      </Box>
    </div>
  );
};
