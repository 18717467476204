import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import { useMobileLayout } from "../hooks/uiHooks";

import Search from "./Search";

import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import SortIcon from "@material-ui/icons/Sort";
import { Tooltip } from "@material-ui/core";

import { DefaultStrings } from "../strings";
import { OverflowMenu } from "components";

const MIN_HEIGHT = 360;
const TOP_MARGIN = 48;

const useStyles = makeStyles((theme) => {
  const border = theme.spacing(2);
  return {
    drawerPaper: ({ mobile }) => ({
      borderRadius: mobile === true ? 0 : `${border}px ${border}px 0 0`,
    }),
    container: ({ showHalf, mobile }) => ({
      height: Math.max(
        MIN_HEIGHT,
        mobile === true
          ? window.innerHeight
          : showHalf === true
          ? (window.innerHeight - TOP_MARGIN) / 2
          : window.innerHeight - TOP_MARGIN
      ),
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      transition: "all 0.5s ease",
      overflow: "hidden",
    }),
    titleBar: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      minHeight: 56,
    },
    title: {
      flexGrow: 1,
      width: 1,
      display: "flex",
      alignItems: "center",
    },
    tabs: {
      flexGrow: 6,
    },
    content: ({ mobile }) => ({
      // minimal formatting and leave it to child components
      backgroundColor: theme.palette.background.default,
      border: "solid 1px #CCCCCC",
      flexGrow: 1,
      height: 1,
      position: "relative",
    }),
    flexCenter: {
      justifyContent: "center",
    },
    tab: {
      minWidth: 100,
      textTransform: "capitalize",
    },
    sortIcon: {
      display: "flex",
      marginLeft: theme.spacing(1),
    },
    sortSelected: {
      backgroundColor: theme.palette.primary.main,
    },
    icons: {
      width: 1,
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    tabContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
    },
    tabHidden: {
      display: "none",
    },
  };
});

// const config = {
//   title: "title",
//   onClose: () => {},
//   canHalfOpen: true,
//   canExpandFull: true,
//   canCopyTitle: true,
//   tabs: [
//     {
//       label: "Label",
//     },
//   ],
//   currentTab: 0,
//   onClickTab: (tab) => {},
//   keepMounted: true,
//   onSearch: (value) => {},
//   sort: [
//     {
//       label: "label",
//       onClick: () => {},
//       selected: false,
//       desc: true,
//     }
//   ]
// };

const PullUpPanel = ({
  children,
  open,
  config: {
    title,
    onClose,
    canHalfOpen,
    canExpandFull,
    canCopyTitle,
    tabs,
    currentTab,
    onClickTab,
    keepMounted,
    onSearch,
    sort,
    actionContent,
    tooltipContent = null,
    overflow,
  },
}) => {
  const hasSearch = !!onSearch;
  const hasSort = sort?.length > 0;

  const mobile = useMobileLayout();
  const [fullyOpened, setFullyOpened] = useState();
  const [searching, setSearching] = useState(false);
  const showHalf = canHalfOpen && !fullyOpened;
  const classes = useStyles({ showHalf, mobile });
  const [anchorSort, setAnchorSort] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const onCopy = (event) => {
    navigator.clipboard.writeText(title);
    enqueueSnackbar(DefaultStrings.COPY_CLIPBOARD, { variant: "info" });
  };

  const onCloseInternal = () => {
    setFullyOpened(false);
    onClose();
    setSearching(false);
  };

  const onSearchInternal = (value) => {
    setSearching(value !== null);
    if (onSearch) onSearch(value);
  };

  const onSort = (func) => {
    setAnchorSort(null);
    if (func) func();
  };

  const onExpand = () => {
    if (canHalfOpen && !mobile) {
      // can half open and not on mobile

      if (fullyOpened || !canExpandFull) {
        // already fully opened or cannot expand => close
        onCloseInternal();
      } else {
        setFullyOpened(true);
      }
    } else {
      // cannot half open or on mobile = always close
      onCloseInternal();
    }
  };

  const expand = mobile ? (
    <CloseIcon />
  ) : showHalf && canExpandFull ? (
    <ExpandLessOutlinedIcon />
  ) : (
    <ExpandMoreOutlinedIcon />
  );

  const onTabChange = (event, newTab) => {
    if (!event) return;
    if (onClickTab) onClickTab(currentTab, newTab);
  };

  const safeTab = Number.isInteger(currentTab)
    ? currentTab >= tabs.length
      ? 0
      : currentTab
    : 0;

  const deviceTabs = tabs && (
    <Tabs
      centered={mobile ? false : true}
      indicatorColor="primary"
      textColor="primary"
      value={safeTab}
      onChange={onTabChange}
      variant={mobile ? "scrollable" : "standard"}
      scrollButtons={mobile ? "on" : "auto"}
    >
      {tabs.map((tab) => (
        <Tab className={classes.tab} key={tab.label} label={tab.label} />
      ))}
    </Tabs>
  );

  const titleContent = title && (!mobile || !searching) && (
    <div className={classes.deviceIMeAndActionContainer}>
      {tooltipContent && !mobile ? (
        <Tooltip title={tooltipContent} arrow>
          <b>{title}</b>
        </Tooltip>
      ) : (
        <b>{title}</b>
      )}
      {canCopyTitle && navigator.clipboard && (
        <IconButton onClick={onCopy}>
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );

  const tabsContent = !mobile && !showHalf && (
    <div className={clsx(classes.title, classes.tabs, classes.flexCenter)}>
      {deviceTabs}
    </div>
  );

  const searchContent = hasSearch && (
    <div style={{ flexGrow: searching && mobile ? 1 : 0 }}>
      <Search mobile={mobile} onSearch={onSearchInternal} />
    </div>
  );

  const sortContent = hasSort && !searching && (
    <>
      <IconButton
        aria-label={DefaultStrings.BUTTON_SORT}
        color="inherit"
        onClick={(e) => setAnchorSort(e.currentTarget)}
      >
        <SortIcon />
      </IconButton>
      <Menu
        id="menuSort"
        anchorEl={anchorSort}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={anchorSort !== null}
        onClose={(e) => setAnchorSort(null)}
      >
        {sort.map((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => onSort(action.onClick)}
            className={clsx(action.selected && classes.sortSelected)}
          >
            <span>{action.label}</span>
            {action.selected && (
              <div className={classes.sortIcon}>
                {action.desc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              </div>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onCloseInternal}
      classes={{ paper: classes.drawerPaper }}
      ModalProps={{
        keepMounted,
      }}
      SlideProps={{
        timeout: 500,
      }}
    >
      <div className={classes.container}>
        <div className={classes.titleBar}>
          {titleContent}
          {tabsContent}
          <div className={classes.icons}>
            {searchContent}
            {sortContent}
            {overflow && <OverflowMenu options={overflow} />}
            {actionContent}
            <IconButton onClick={onExpand}>{expand}</IconButton>
          </div>
        </div>
        {mobile && deviceTabs}
        <div className={classes.content}>
          {tabs
            ? tabs.map((tab, index) => (
                <div
                  key={`tab-${tab.label}`}
                  className={clsx(
                    classes.tabContainer,
                    currentTab !== index && classes.tabHidden
                  )}
                >
                  {tab.render(showHalf, currentTab === index, !open)}
                </div>
              ))
            : children}
        </div>
      </div>
    </Drawer>
  );
};

export default PullUpPanel;
