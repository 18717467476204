import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useProjectShareCodeMembers } from "services/ProjectService";
import { useMobileLayout } from "hooks/uiHooks";
import { TeamCodeStrings } from "strings";
import UserAvatar from "ui/UserAvatar";
import { useStyles } from "./style";

export const Team = ({ code }) => {
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile });
  const members = useProjectShareCodeMembers(code);
  const avatarTeamSize = mobile ? 7 : 5;

  if (members) {
    return (
      <div className={classes.codeTeam}>
        <Typography variant="body1">
          {TeamCodeStrings.TEAM_CODE_TEAM} ({members?.length})
        </Typography>
        <div style={{ display: "flex" }}>
          {members.map((m, index) => (
            <Box key={m.usersId} ml={index === 0 ? "unset" : -0.5}>
              <UserAvatar userId={m.usersId} size={avatarTeamSize} />
            </Box>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
