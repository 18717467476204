import ROUTES from "./routes";

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;

export const getProjectNewRoute = () => ROUTES.PROJECT_NEW;

export const getRegisterDeviceRoute = (deviceId) =>
  ROUTES.REGISTER_DEVICE.replace(":deviceId", deviceId);

export const getJoinRoute = (code) => ROUTES.JOIN.replace(":code", code);
export const getProjectJoinRoute = (code) =>
  ROUTES.PROJECT_JOIN.replace(":code", code);

export const getJoinUrl = (code) => `${PROTOCOL}//${HOST}${getJoinRoute(code)}`;

export const getOrganisationDashboardRoute = (organisationId) =>
  ROUTES.ORGANISATION_DASHBOARD.replace(":organisationId", organisationId);

export const getOrganisationTeamRoute = (organisationId) =>
  ROUTES.ORGANISATION_TEAM.replace(":organisationId", organisationId);

export const getOrganisationProjectsRoute = (organisationId) =>
  ROUTES.ORGANISATION_PROJECTS.replace(":organisationId", organisationId);

export const getOrganisationGuestsRoute = (organisationId) =>
  ROUTES.ORGANISATION_GUESTS.replace(":organisationId", organisationId);

export const getOrganisationActivityRoute = (organisationId) =>
  ROUTES.ORGANISATION_ACTIVITY.replace(":organisationId", organisationId);

export const getOrganisationSettingsRoute = (organisationId) =>
  ROUTES.ORGANISATION_SETTINGS.replace(":organisationId", organisationId);

export const getProjectOverviewRoute = (projectId) =>
  ROUTES.PROJECT_OVERVIEW.replace(":projectId", projectId);

export const getProjectMediaRoute = (projectId) =>
  ROUTES.PROJECT_MEDIA.replace(":projectId", projectId);

export const getProjectMediaEditTabRoute = (projectId, tab) =>
  ROUTES.PROJECT_MEDIA_EDIT_TAB.replace(":projectId", projectId).replace(
    ":tab",
    tab
  );

export const getProjectMediaPreviewRoute = (projectId, draftId) =>
  ROUTES.PROJECT_MEDIA_PREVIEW.replace(":projectId", projectId).replace(
    ":draftId",
    draftId
  );

export const getProjectMediaMessagesRoute = (projectId) =>
  ROUTES.PROJECT_MEDIA_MESSAGES.replace(":projectId", projectId);

export const getProjectDevicesRoute = (projectId) =>
  ROUTES.PROJECT_DEVICES.replace(":projectId", projectId);

export const getProjectDeviceSingleRoute = (projectId, deviceId) =>
  ROUTES.PROJECT_DEVICE_SINGLE.replace(":projectId", projectId).replace(
    ":deviceId",
    deviceId
  );

export const getProjectDeviceTabRoute = (projectId, deviceId, tab) =>
  ROUTES.PROJECT_DEVICE_TAB.replace(":projectId", projectId)
    .replace(":deviceId", deviceId)
    .replace(":tab", tab);

export const getProjectAnalyticsRoute = (projectId) =>
  ROUTES.PROJECT_ANALYTICS.replace(":projectId", projectId);

export const getProjectTeamRoute = (projectId) =>
  ROUTES.PROJECT_TEAM.replace(":projectId", projectId);

export const getProjectTeamShareTabRoute = (
  projectId,
  tab = "share-awaiting-approval"
) =>
  ROUTES.PROJECT_TEAM_SHARE_TAB.replace(":projectId", projectId).replace(
    ":tab",
    tab
  );

export const getProjectsRoute = () => ROUTES.PROJECTS;

// extract the projectId from a location.pathname
export const getProjectIdFromPathname = (pathname) =>
  pathname.match(/\/project\/(\w+)\//)?.[1];

export const getOrganisationIdFromPathname = (pathname) =>
  pathname.match(/\/organisation\/(\w+)\//)?.[1];

export const getProfileRoute = () => ROUTES.PROFILE;

export const getDeviceRemoteRoute = (deviceId) =>
  ROUTES.REMOTE_DEVICE.replace(":deviceId", deviceId);

export { ROUTES };
