import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from "react";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { getAllProjects } from "services/ApiService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { ProjectStrings } from "strings";
import Spinner from "ui/Spinner";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import { PROJECT_SORT_OPTION_FIELD } from "../config";
import InfiniteScroll from "ui/InfiniteScroll";
import { BELONGING_TO_OPTIONS, BELONGING_TO_VALUE } from "./config";
import { EmptyData } from "ui/emptyData";
import { getProjectOverviewRoute } from "route";
import { useTotalProjectsCountSuperAdmin } from "services/ProjectService";

const Projects = forwardRef((props, ref) => {
  const history = useHistory();
  const [startAfter, setStartAfter] = useState();
  const [projectsIds, setProjectsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [belongingToOrg, setBelongingToOrg] = useState(BELONGING_TO_VALUE.ALL);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [totalProjects, setTotalProjects] = useState();
  const projectsTotalCount = useTotalProjectsCountSuperAdmin();

  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small: false });

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const getParams = useCallback((params) => {
    const {
      searchValue,
      sortDesc,
      lastKey,
      belongingToOrg: belongingTo,
    } = params;
    const orderBy =
      params.sortValue && params.sortValue in PROJECT_SORT_OPTION_FIELD
        ? PROJECT_SORT_OPTION_FIELD[params.sortValue]
        : PROJECT_SORT_OPTION_FIELD.SORT_OPTION_NAME;

    return {
      limit: 10,
      orderBy,
      searchValue,
      lastKey,
      orderDesc: sortDesc,
      belongingToOrg: belongingTo,
    };
  }, []);

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        belongingToOrg,
        ...props,
        ...params,
      });

      try {
        const response = await getAllProjects(requestParams);
        const { items, total } = response.result;
        setTotalProjects(total);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...items]);
        } else {
          setProjectsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [belongingToOrg, getParams, isRequestSent, projectsIds, props]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // do request if total count changed for projects
    if (
      typeof totalProjects !== "undefined" &&
      typeof projectsTotalCount !== "undefined" &&
      totalProjects !== projectsTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsTotalCount, totalProjects]);

  useImperativeHandle(
    ref,
    () => ({
      doRequest: (opt) => {
        resetData();
        fetchData(opt);
      },
    }),
    [fetchData]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  const configCard = {
    showParticipants: true,
    showPin: false,
    showOrgName: true,
    onClick: (projectId) => {
      history.push(getProjectOverviewRoute(projectId));
    },
  };

  const config = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      fetchData({ lastKey: startAfter });
    },
  };

  const onClickClear = () => {
    setBelongingToOrg(BELONGING_TO_VALUE.ALL);
    resetData();
    fetchData({ belongingToOrg: BELONGING_TO_VALUE.ALL });
  };

  const onSelectBelonging = (ev) => {
    const { value } = ev.target;
    setBelongingToOrg(value);
    resetData();
    fetchData({ belongingToOrg: value });
  };

  const renderContent = () => {
    if (typeof projectsIds === "undefined") return <Spinner />;

    if (!projectsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            props.searchValue
              ? ProjectStrings.LIST_NO_FOR_SEARCH
              : ProjectStrings.LIST_NO_PROJECTS
          }
          icon={
            props.searchValue ? <SearchIcon /> : <ViewCarouselOutlinedIcon />
          }
        />
      );

    return (
      <InfiniteScroll config={config} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds.map((projectId) => (
            <div key={projectId} className={classes.cardContainer}>
              <ProjectCard projectId={projectId} config={configCard} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <FormControl className={classes.formControl}>
          <InputLabel id="belonging" className={classes.formLabel}>
            {ProjectStrings.PROJECTS_FILTER_ORGANISATION_NAME}
          </InputLabel>
          <Select
            style={{ padding: "6px" }}
            value={belongingToOrg}
            onChange={onSelectBelonging}
          >
            {BELONGING_TO_OPTIONS.map((opt) => {
              return (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button style={{ marginTop: "16px" }} onClick={onClickClear}>
          Clear
        </Button>
      </div>
      {renderContent()}
    </div>
  );
});

export default Projects;
