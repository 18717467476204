import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { debounce } from "../services/UiService";
import { ConfirmDialogContext } from "../context/ConfirmDialogProvider";

export const useMobileLayout = () => {
  const size = useWindowSize();
  const landscape = size?.width > size?.height;
  const query = landscape ? "(max-height:600px)" : "(max-width:600px)";
  return useMediaQuery(query, { noSsr: true });
};

export const useBreakpoints = () => {
  const theme = useTheme();

  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return { isLargeDesktop, isTablet, isMobile };
};

export const useConfirm = () => useContext(ConfirmDialogContext);

export const useWindowSize = (delay = 500) => {
  const [dimensions, setDimensions] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  };
  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, delay);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  return dimensions;
};
