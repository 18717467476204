import React from "react";

import NoteCard from "./NoteCard";

import {
  useProjectNote,
  useUserProjectPermissions,
} from "services/ProjectService";
import { updateProjectNote } from "services/ApiService";

const ProjectNoteCard = ({ userId, projectId }) => {
  const { canReadProjectExtra, canWriteProjectExtra } =
    useUserProjectPermissions({
      userId,
      projectId,
    });
  const projectNote = useProjectNote(projectId);

  const onSave = (note) => updateProjectNote({ projectId, note });

  if (
    typeof canReadProjectExtra === "undefined" ||
    typeof canWriteProjectExtra === "undefined"
  )
    return null;

  return (
    <NoteCard
      defaultNote={projectNote}
      onSave={onSave}
      canRead={canReadProjectExtra}
      canEdit={canWriteProjectExtra}
    />
  );
};

export default ProjectNoteCard;
