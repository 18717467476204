import { API_PATHS, postPrivateRequest } from "./ApiService";

export const checkDomainApi = (domain) => {
  const path = API_PATHS.ORGANISATION_CHECK_DOMAIN;
  console.debug("checkDomain", path);
  return postPrivateRequest({ path, data: { domain } });
};

export const checkUserEmailApi = (params) => {
  const path = API_PATHS.ORGANISATION_CHECK_USER_EMAIL;
  console.debug("checkUserEmailApi", path);
  return postPrivateRequest({ path, data: params });
};

export const precreateApi = (connectedDomains) => {
  const path = API_PATHS.ORGANISATION_PRECREATE;
  console.debug("precreateOrganisation", path);
  return postPrivateRequest({ path, data: { connectedDomains } });
};

export const createApi = (params) => {
  const path = API_PATHS.ORGANISATION_CREATE;
  console.debug("createOrganisation", path);
  return postPrivateRequest({ path, data: params });
};

export const updateInfoApi = ({ organisationId, info }) => {
  const path = API_PATHS.ORGANISATION_UPDATE_INFO;
  const data = {
    organisationId,
    ...info,
  };

  console.debug("updateOrganisationInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteInfoApi = (organisationId) => {
  const path = API_PATHS.ORGANISATION_DELETE_INFO;
  const data = {
    organisationId,
  };

  console.debug("deleteOrganisationInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const removeUserApi = ({ organisationId, userId }) => {
  const path = API_PATHS.ORGANISATION_USER_REMOVE;
  const data = {
    organisationId,
    targetUserId: userId,
  };
  console.debug("removeUser", path, data);
  return postPrivateRequest({ path, data });
};

export const updateUserRoleApi = ({ organisationId, userId, role }) => {
  const path = API_PATHS.ORGANISATION_USER_UPDATE_ROLE;
  const data = {
    organisationId,
    targetUserId: userId,
    role,
  };
  console.debug("updateUserRole", path, data);
  return postPrivateRequest({ path, data });
};

export const statsOrganisationApi = async (organisationId) => {
  const path = API_PATHS.ORGANISATION_STATS;
  const data = {
    organisationId,
  };
  console.debug("statsOrganisation", path, data);
  return await postPrivateRequest({ path, data });
};

export const acceptOrganisationInviteApi = async (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_ACCEPT;

  console.debug("acceptOrganisationInviteApi", path);
  return await postPrivateRequest({ path, data });
};

export const declineOrganisationInviteApi = async (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_DECLINE;

  console.debug("declineOrganisationInviteApi", path);
  return await postPrivateRequest({ path, data });
};

// organisations for SuperAdmin
export const getAllOrganisationsApi = (params) => {
  const path = API_PATHS.ORGANISATIONS_LIST;

  console.debug("getAllOrganisationsApi", path, params);
  return postPrivateRequest({ path, data: params });
};
// organisations for user
export const getMyOrganisationsApi = (params) => {
  const path = API_PATHS.ORGANISATIONS_MY_LIST;

  console.debug("getMyOrganisationsApi", path, params);
  return postPrivateRequest({ path, data: params });
};

export const getOrganisationUsersApi = (params) => {
  const path = API_PATHS.ORGANISATION_USERS_LIST;

  console.debug("getOrganisationUsersApi", path, params);
  return postPrivateRequest({ path, data: { ...params } });
};

export const inviteUsersApi = (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_SEND;

  console.debug("inviteUsersApi", path, data);
  return postPrivateRequest({ path, data });
};

export const updateUserInviteApi = (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_UPDATE;

  console.debug("updateUserInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteUsersInviteApi = (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_DELETE;

  console.debug("deleteUsersInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const getUsersPendingInviteApi = (data) => {
  const path = API_PATHS.ORGANISATION_INVITE_PENDING;

  console.debug("getUsersPendingInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const leaveApi = async (data) => {
  const path = API_PATHS.ORGANISATION_USER_LEAVE;

  console.debug("leaveApi", path);
  return await postPrivateRequest({ path, data });
};

export const getOrganisationPreviewApi = (data) => {
  const path = API_PATHS.ORGANISATION_PREVIEW;

  console.debug("getOrganisationPreviewApi", path, data);
  return postPrivateRequest({ path, data });
};
