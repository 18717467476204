import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { useMobileLayout } from "hooks/uiHooks";
import {
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_SORT_OPTION,
} from "utils/projectUtils";
import { isLoading } from "utils/uiUtils";
import { getOrganisationProjects, getMyProjects } from "services/ApiService";
import ProjectCard from "./cards/projectCard/ProjectCard";
import Spinner from "./Spinner";
import InfiniteScroll from "./InfiniteScroll";
import { EmptyData } from "./emptyData";
import { getCurrentUserId } from "services/UserService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { ProjectStrings } from "strings";
import {
  useTotalOrganisationProjectsCount,
  useTotalUserProjectsCount,
} from "services/ProjectService";

const LIMIT_DEFAULT = 10;

const useStyles = makeStyles((theme) => {
  return {
    cardContainer: ({ mobile, small }) => ({
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : small ? 240 : 320,
      height: small ? 240 : 360,
      boxSizing: "border-box",
    }),
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden",
    },
    main: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  };
});

const ProjectList = forwardRef((props, ref) => {
  const { config, ...restParams } = props;
  const {
    organisationId,
    exclude,
    small = false,
    showOrgProjects,
    onClick,
    onAdd,
  } = config;
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small });

  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [projectsIds, setProjectsIds] = useState();
  const [totalProjects, setTotalProjects] = useState();
  const [startAfter, setStartAfter] = useState();
  const [hoverProjectId, setHoverProjectId] = useState();
  const [isRequestSent, setIsRequestSent] = useState(false);
  const userId = getCurrentUserId();
  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });
  const orgProjectsTotalCount = useTotalOrganisationProjectsCount(
    showOrgProjects && organisationId
  );
  const myProjectsTotalCount = useTotalUserProjectsCount({
    userId: !showOrgProjects && userId,
    organisationId: !showOrgProjects && organisationId,
  });

  const emptyDataText = useMemo(() => {
    if (projectsIds && projectsIds.length) return {};

    if (showOrgProjects) {
      return {
        title: props.searchValue
          ? ProjectStrings.PROJECTS_NO_FOR_SEARCH
          : ProjectStrings.PROJECTS_NO_IN_ORGANISATION,

        description: ProjectStrings.PROJECTS_NO_IN_ORGANISATION_DESC,
        actionTitle: props.searchValue
          ? null
          : ProjectStrings.PROJECTS_ADD_FIRST,
      };
    }

    return {
      title: props.searchValue
        ? ProjectStrings.PROJECTS_NO_FOR_SEARCH
        : ProjectStrings.PROJECTS_NO_PROJECTS,

      description: ProjectStrings.PROJECTS_NO_MY_PROJECTS_DESC,
      actionTitle: props.searchValue ? null : ProjectStrings.PROJECTS_ADD_FIRST,
    };
  }, [projectsIds, props.searchValue, showOrgProjects]);

  const resetData = () => {
    setIsFetchedAll(false);
    setStartAfter();
    setIsRequestSent(true);
  };

  const getParams = useCallback(
    (params) => {
      const { searchValue, sortDesc, lastKey } = params;
      const orderBy =
        params.sortValue && params.sortValue in PROJECT_SORT_OPTION_FIELD
          ? PROJECT_SORT_OPTION_FIELD[params.sortValue]
          : PROJECT_SORT_OPTION_FIELD[PROJECT_SORT_OPTION[0]];

      const paramsForRequest = {
        organisationId,
        limit: LIMIT_DEFAULT,
        orderBy,
        searchValue,
        lastKey,
        orderDesc: sortDesc,
      };

      return paramsForRequest;
    },
    [organisationId]
  );

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        ...restParams,
        ...params,
      });

      try {
        let responseData;

        if (showOrgProjects && organisationId) {
          // get all projects for the organisation
          const res = await getOrganisationProjects({ ...requestParams });
          responseData = res.result.items;
          setTotalProjects(res.result.total);
        } else {
          // get all user's projects in organisation and not assinged to any organisations
          const res = await getMyProjects({ ...requestParams });
          responseData = res.result.items;
          setTotalProjects(res.result.total);
        }

        if (responseData.length === 0 || responseData.length < LIMIT_DEFAULT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...responseData]);
        } else {
          setProjectsIds(responseData);
          setIsRequestSent(false);
        }

        if (responseData.length) {
          setStartAfter(responseData[responseData.length - 1]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParams, restParams, showOrgProjects, isRequestSent, projectsIds]
  );

  useEffect(() => {
    if (organisationId || organisationId === null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    // do request if total count changed for organisation projects
    if (
      showOrgProjects &&
      typeof totalProjects !== "undefined" &&
      typeof orgProjectsTotalCount !== "undefined" &&
      totalProjects !== orgProjectsTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrgProjects, totalProjects, orgProjectsTotalCount]);

  useEffect(() => {
    // do request if total count changed for user projects
    if (
      !showOrgProjects &&
      typeof totalProjects !== "undefined" &&
      typeof myProjectsTotalCount !== "undefined" &&
      totalProjects !== myProjectsTotalCount
    ) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrgProjects, totalProjects, myProjectsTotalCount]);

  useImperativeHandle(
    ref,
    () => ({
      doRequest: (opt) => {
        resetData();
        fetchData(opt);
      },
    }),
    [fetchData]
  );

  const configCard = {
    showBrand: true,
    showParticipants: !small,
    useUnread: true,
    showOrgName: !showOrgProjects,
    showPin: !showOrgProjects,
    onPinProject: () => {
      resetData();
      fetchData();
    },
  };

  const configScroll = {
    onLoadMore: () => {
      if (isFetchedAll) return;

      fetchData({ lastKey: startAfter });
    },
  };

  const onAddProjectHandle = () => {
    if (onAdd) onAdd();
  };

  const renderContent = (() => {
    if (isLoading(projectsIds)) return <Spinner />;

    if (!projectsIds.length)
      return (
        <EmptyData
          title={emptyDataText.title}
          description={!props.searchValue && emptyDataText.description}
          actionTitle={emptyDataText.actionTitle}
          icon={
            props.searchValue ? <SearchIcon /> : <ViewCarouselOutlinedIcon />
          }
          onClick={canWriteProjects && onAddProjectHandle}
        />
      );

    return (
      <InfiniteScroll config={configScroll} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds
            .filter(
              (projectId) =>
                projectId && (!exclude || !exclude.includes(projectId))
            )
            .map((projectId) => (
              <div
                key={projectId}
                className={classes.cardContainer}
                onMouseOver={() => {
                  setHoverProjectId(projectId);
                }}
              >
                <ProjectCard
                  userId={userId}
                  projectId={projectId}
                  config={{
                    ...configCard,
                    hoverProjectId,
                    onClick: (projectId, joined) => onClick(projectId, joined),
                  }}
                />
              </div>
            ))}
        </div>
      </InfiniteScroll>
    );
  })();

  return <div className={classes.root}>{renderContent}</div>;
});

export default ProjectList;
