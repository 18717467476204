import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { useUserInfo } from "services/UserService";

import { generateColorByFirstLetter } from "utils/generalUtils";
import Spinner from "./Spinner";

const DEFAULT_SIZE = 9;

const useStyles = makeStyles((theme) => ({
  avatar: ({ size }) => ({
    border: "2px solid #F4F4F4",
    height: theme.spacing(size),
    width: theme.spacing(size),
  }),
  emptyAvatar: ({ size, colour }) => ({
    position: "relative",
    border: "2px solid #F4F4F4",
    height: theme.spacing(size),
    width: theme.spacing(size),
    display: "flex",
    color: "white",
    backgroundColor: colour,
    fontWeight: 700,
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  }),
}));

const UserAvatar = ({ userId, userEmail, size = DEFAULT_SIZE }) => {
  const userInfo = useUserInfo(userId);
  const classes = useStyles({
    size,
    colour: generateColorByFirstLetter(userInfo?.name || userEmail),
  });

  const renderContent = () => {
    if (userInfo?.img) {
      return (
        <Avatar
          className={classes.avatar}
          src={userInfo.img}
          alt={userInfo.name}
        />
      );
    }
    return (
      <div className={classes.emptyAvatar}>
        {!userInfo && !userEmail && <Spinner size={16} />}
        <Typography>
          {userInfo?.name[0].toUpperCase() ||
            (userEmail && userEmail[0].toUpperCase())}
        </Typography>
      </div>
    );
  };

  return (
    <Tooltip title={(userInfo?.name || userEmail) ?? ""} placement="top">
      {renderContent()}
    </Tooltip>
  );
};

export default UserAvatar;
