import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    main: () => ({
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(2),
    }),
    content: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  };
});
