import React, { useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

import MainPage from "../MainPage";
import ProjectTeamList from "ui/ProjectTeamList";
import RestrictedContent from "ui/RestrictedContent";
import TeamCodePanel from "ui/teamCodePanel/TeamCodePanel";
import { PROJ_PERMISSION_NAMES } from "utils/permissionUtils";
import {
  useUserProjectPermissions,
  useProjectTitle,
} from "services/ProjectService";
import { useProjectInfo } from "hooks/projectHooks";
import { TEAM_SORT_OPTION } from "utils/teamUtils";
import { removeUser, updateUserRole } from "services/ApiService";
import { TeamMemberDialog } from "ui/dialogs";
import {
  ROUTES,
  getProjectTeamRoute,
  getProjectTeamShareTabRoute,
} from "route";
import { ProjectStrings, TeamCodeStrings } from "strings";
import { useProjectOverviewTabs } from "./";
import PendingApprovalsCard from "ui/cards/pendingApprovalsCard/PendingApprovalsCard";
import { withInProjectCheck } from "hocs";
import { updateUrl } from "services/UiService";

// Trello ticket on permissions: https://trello.com/c/dZzUlDeV/107-add-permission-control-for-project-team-page
const ProjectTeamPage = ({ userId }) => {
  const params = useParams();
  const projectId = params.projectId;
  const matchManage = useRouteMatch(ROUTES.PROJECT_TEAM_SHARE_TAB);
  const projectInfo = useProjectInfo(projectId);
  const title = useProjectTitle(projectId);
  const [searchString, setSearchString] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [sortDesc, setSortDesc] = useState(false);
  const [showPanel, setShowPanel] = useState(!!matchManage);
  const [progressId, setProgressId] = useState();
  const [userInfo, setUserInfo] = useState(null);

  // permissions control
  const projPermissions = useUserProjectPermissions({ userId, projectId });
  const {
    canReadCode,
    canWriteCode,
    canReadUsers,
    canReadUserPermissions,
    canWriteProject,
  } = projPermissions;

  const tabs = useProjectOverviewTabs({
    projectId,
    tabKey: "TEAM",
  });

  const onShowPanel = (show) => {
    setShowPanel(show);
    const url = show
      ? getProjectTeamShareTabRoute(projectId)
      : getProjectTeamRoute(projectId);
    updateUrl(url);
  };

  const configSortOptions = TEAM_SORT_OPTION.map((opt) => ({
    label: ProjectStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
  }));

  const config = {
    userId,
    actionButton: {
      primary: {
        icon: <ShareOutlinedIcon />,
        name: TeamCodeStrings.TEAM_CODE_SHARE,
        onClick: () => {
          onShowPanel(true);
        },
        disabled: !canWriteCode,
      },
    },
    appBar: {
      tabDefault: "TEAM",
      tabs,
      onSearch:
        canReadUsers &&
        ((value) => {
          if (value && value.length > 1) setSearchString(value);
          else setSearchString(null);
        }),
      sort: canReadUsers && configSortOptions,
      overflow: [
        {
          label: TeamCodeStrings.TEAM_CODE_MANAGE,
          onClick: () => {
            onShowPanel(true);
          },
          disabled: !canReadCode,
        },
      ],
      title,
    },
    fixedHeight: true,
  };

  const configTeamList = {
    filter: searchString,
    sortOption,
    sortDesc,
    projectId,
    onClick: setUserInfo,
    onOpenCode: () => setShowPanel(true),
    progressId,
  };

  const configPanel = {
    title: TeamCodeStrings.TEAM_CODE_MANAGE,
    projectInfo,
    onClose: () => onShowPanel(false),
  };

  const onProgressIdHandle = (userId) => setProgressId(userId);
  const onCloseHandle = () => setUserInfo(null);
  const onRemoveUserHandle = () =>
    removeUser({
      projectId,
      userId: userInfo.userId,
    });
  const onUpdateUserRoleHandle = (role) =>
    updateUserRole({
      projectId,
      role,
      userId: userInfo.userId,
    });

  return (
    <MainPage config={config}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PendingApprovalsCard
          projectId={projectId}
          canWrite={canWriteProject}
          onOpen={() => onShowPanel(true)}
        />
        <RestrictedContent permitted={canReadUsers}>
          <ProjectTeamList
            config={configTeamList}
            canReadPermissions={canReadUserPermissions}
          />
        </RestrictedContent>
      </div>
      <TeamMemberDialog
        userInfo={userInfo}
        userId={userId}
        permissions={projPermissions}
        permissionNames={PROJ_PERMISSION_NAMES}
        confirmTitle={TeamCodeStrings.TEAM_MEMBER_REMOVE_CONFIRM_TITLE}
        confirmDesc={TeamCodeStrings.TEAM_MEMBER_REMOVE_CONFIRM_DESC}
        onProgressId={onProgressIdHandle}
        onRemoveUser={onRemoveUserHandle}
        onUpdateUserRole={onUpdateUserRoleHandle}
        onClose={onCloseHandle}
      />
      <TeamCodePanel
        userId={userId}
        projectId={projectId}
        open={showPanel}
        config={configPanel}
      />
    </MainPage>
  );
};

export default withInProjectCheck(ProjectTeamPage);
