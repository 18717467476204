import React, { memo, useEffect, useState, useRef } from "react";
import { Box, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { useMobileLayout, useConfirm } from "../hooks/uiHooks";
import {
  ccTransfer,
  transferDevice,
  transferQRDevice,
} from "../services/ApiService";
import { areDictsEqual } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";

import ProjectList from "./ProjectList";
import ProgressDialog from "./dialogs/ProgressDialog";
import ProjectPreviewTransferDialog from "./dialogs/ProjectPreviewTransferDialog";
import RestrictedContent from "./RestrictedContent";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Spinner from "./Spinner";

import { DefaultStrings, DeviceStrings } from "../strings";
import { DEVICE_PLATFORMS } from "services/DeviceService";

const useStyles = makeStyles((theme) => {
  return {
    root: (mobile) => ({
      height: "100%",
      width: "100%",
      padding: theme.spacing(mobile ? 1 : 2),
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    }),
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    listContainer: {
      flexGrow: 1,
      height: 1,
    },
    stretchWidth: (mobile) => ({
      flexGrow: 1,
      width: mobile ? "100%" : 1,
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }),
    stretchHeight: {
      flexGrow: 1,
      height: 1,
      paddingTop: theme.spacing(2),
      "&:first-child": {
        paddingTop: 0,
      },
    },
    iconButton: {
      padding: 0,
    },
    dialog: (mobile) => ({
      width: mobile ? "100%" : 320,
      height: mobile ? "100%" : "80%",
    }),
    actions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
    },
    button: {
      width: "60%",
      margin: theme.spacing(1),
      "&:last-child": {
        marginTop: 0,
      },
    },
  };
});

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.active === props2?.active &&
  props1?.dismiss === props2?.dismiss &&
  areDictsEqual(props1.permissions, props2.permissions);

let searchTimer;

const DeviceTransfer = ({
  devices,
  projectId,
  permissions,
  active,
  dismiss,
  useCc = false,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [activeProjectId, setActiveProjectId] = useState();
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const projectsListRef = useRef(null);

  // check dismiss
  useEffect(() => {
    if (dismiss) {
      setActiveProjectId(null);
      setShowProgressDialog(false);
    }
  }, [dismiss]);

  if (isLoading(devices) || isLoading(active)) return <Spinner />;

  if (!devices) return <></>;

  const onClickProject = (projectId) => {
    // show PreviewDialog
    setActiveProjectId(projectId);
  };

  const onTransfer = async () => {
    // dismiss PreviewDialog
    setActiveProjectId(null);

    // show ProgressDialog
    // DevicePanel will detect project change and dismiss
    setShowProgressDialog(true);
    const newProjectId = activeProjectId;

    const [deviceArray, qrDeviceArray] = [
      Object.entries(devices)
        .filter(([_, device]) => device.platform !== DEVICE_PLATFORMS.QRID)
        .map(([id, _]) => id),
      Object.entries(devices)
        .filter(([_, device]) => device.platform === DEVICE_PLATFORMS.QRID)
        .map(([id, _]) => id),
    ];

    let deviceResult, qrDeviceResult;
    if (deviceArray.length) {
      const tranFunc = useCc ? transferDevice : ccTransfer;
      deviceResult = await tranFunc({
        deviceId: deviceArray,
        projectId,
        newProjectId,
      });
    }
    if (qrDeviceArray.length) {
      qrDeviceResult = await transferQRDevice({
        deviceId: qrDeviceArray,
        projectId,
        newProjectId,
      });
    }
    if (
      (deviceResult && !deviceResult.success) ||
      (qrDeviceResult && !qrDeviceResult.success)
    ) {
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      console.warn(deviceResult.errors[0], qrDeviceResult.errors[0]);
    }
  };

  const onSearch = (value) => {
    if (value === searchString) return;

    projectsListRef.current.doRequest({
      searchValue: value,
    });
  };

  const onChange = function (e) {
    setSearchString(e.target.value);

    clearTimeout(searchTimer);
    const value = e.target.value;
    searchTimer = setTimeout(() => onSearch(value), 500);
  };

  const endAdornment =
    searchString.length === 0 ? (
      <SearchIcon />
    ) : (
      <IconButton
        className={classes.iconButton}
        onClick={() => setSearchString("")}
      >
        <CloseIcon />
      </IconButton>
    );

  const selectProject = (
    <div className={classes.main}>
      <Box m={1}>{DeviceStrings.TRANSFER_DESC}</Box>
      <TextField
        label={DeviceStrings.TRANSFER_SEARCH}
        value={searchString}
        onChange={onChange}
        size="small"
        InputProps={{
          endAdornment,
        }}
      ></TextField>
    </div>
  );

  const configProjectList = {
    exclude: [projectId],
    small: true,
    onClick: onClickProject,
  };

  const configPreviewDialog = {
    projectId: activeProjectId,
    onClose: () => {
      setActiveProjectId(null);
    },
    onConfirm: () => {
      const message = DeviceStrings.TRANSFER_CONFIRM;
      confirm({ message })
        .then(() => {
          onTransfer();
        })
        .catch((error) => {});
    },
  };

  // usually we want to avoid repetitive mount and unmount, but ProjectList is expensive we don't want it to load until it's active
  // ie. postpone the loading until we actually want to transfer
  return (
    <RestrictedContent permitted={permissions?.canWriteDevices}>
      <div className={classes.root}>
        {selectProject}
        <div className={classes.listContainer}>
          {active && (
            <ProjectList
              ref={projectsListRef}
              config={configProjectList}
              searchValue={searchString}
            />
          )}
        </div>
        <ProgressDialog
          open={showProgressDialog}
          config={{
            title: DeviceStrings.TRANSFER_TITLE,
            desc: DeviceStrings.TRANSFER_PROGRESS,
          }}
        />
        <ProjectPreviewTransferDialog config={configPreviewDialog} />
      </div>
    </RestrictedContent>
  );
};

export default memo(DeviceTransfer, isEqual);
