import React, { useState, useEffect } from "react";
import { MenuItem, Select } from "@material-ui/core";

import MyCard from "./MyCard";
import MyDialog from "../MyDialog";
import TrafficOutlinedIcon from "@material-ui/icons/TrafficOutlined";

import {
  STATUS,
  useProjectStatus,
  useUserProjectPermissions,
} from "../../services/ProjectService";
import { updateProjectStatus } from "../../services/ApiService";

import { ProjectStrings } from "../../strings";

const StatusCard = ({ userId, projectId }) => {
  const projectStatus = useProjectStatus(projectId);
  const { canReadProjectExtra, canWriteProjectExtra } =
    useUserProjectPermissions({ userId, projectId });

  const [showDialog, setShowDialog] = useState(false);
  const [status, setStatus] = useState();
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (showDialog) {
      setStatus(projectStatus);
    }
  }, [projectStatus, showDialog]);

  const configCard = {
    icon: <TrafficOutlinedIcon />,
    info: ProjectStrings.OVERVIEW_STATUS_CARD_INFO,
    title: ProjectStrings.OVERVIEW_STATUS_CARD_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
    progress,
  };

  const configDialog = {
    icon: <TrafficOutlinedIcon />,
    title: ProjectStrings.OVERVIEW_STATUS_CARD_TITLE,
    onClose: () => {
      setShowDialog(false);
      setStatus(projectStatus);
    },
    onOk: () => {
      setShowDialog(false);
      setProgress(true);
      updateProjectStatus({ projectId, status }).then(() => {
        setProgress(false);
      });
    },
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <>
      <MyCard
        config={configCard}
        canRead={canReadProjectExtra}
        canEdit={canWriteProjectExtra}
      >
        {projectStatus}
      </MyCard>
      <MyDialog open={showDialog} config={configDialog}>
        <Select value={status} onChange={handleChange}>
          {Object.entries(STATUS).map(([key, value]) => {
            return (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </MyDialog>
    </>
  );
};

export default StatusCard;
