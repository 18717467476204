import React, { useMemo } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { useUserName, useUserPicture, useUserEmail } from "hooks/userHooks";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { useUserProjectPermissions } from "services/ProjectService";
import { getNameInitials } from "utils/generalUtils";
import { useStyles } from "./styles";
import LinearSpinner from "ui/LinearSpinner";

const TeamMemberCard = ({
  userId,
  projectId,
  organisationId,
  config,
  canReadPermissions,
  progress,
}) => {
  const classes = useStyles();
  const userName = useUserName(userId);
  const userEmail = useUserEmail(userId);
  const userImage = useUserPicture(userId);
  const { userPermissions: userProjPermissions } = useUserProjectPermissions({
    userId,
    projectId,
  });
  const { userPermissions: userOrgPermissions } =
    useUserOrganisationPermissions({
      userId,
      organisationId,
    });
  const permissionsKey = useMemo(() => {
    if (userProjPermissions) return userProjPermissions.permissionsKey;
    if (userOrgPermissions) return userOrgPermissions.permissionsKey;
    return null;
  }, [userOrgPermissions, userProjPermissions]);
  const permissionName = useMemo(() => {
    if (userProjPermissions) return userProjPermissions.name;
    if (userOrgPermissions) return userOrgPermissions.name;
    return null;
  }, [userOrgPermissions, userProjPermissions]);

  const userNameLetters = getNameInitials(userName);
  const onClick = config && config.onClick;

  const firstName = userName && userName.length > 0 && userName.split(" ")[0];

  const gotoProfile = () => {
    if (onClick)
      onClick({
        userId,
        userImage,
        userEmail,
        userNameLetters,
        userName,
        permissionName,
        permissionsKey,
      });
  };

  return (
    <Card className={classes.card}>
      {progress && <LinearSpinner />}
      <CardActionArea
        className={classes.action}
        onClick={!progress ? gotoProfile : null}
      >
        {userImage ? (
          <CardMedia className={classes.media} image={userImage} />
        ) : (
          <Avatar
            className={clsx(classes.media, classes.emptyMedia)}
            variant="square"
          >
            {userNameLetters}
          </Avatar>
        )}
        <CardContent className={classes.content}>
          {firstName && (
            <Typography className={classes.name}>{firstName}</Typography>
          )}
          {permissionName && canReadPermissions && (
            <Typography className={classes.permission}>
              {permissionName}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamMemberCard;
